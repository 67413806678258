import React, { useEffect, useState } from "react";
import Wrapper from "../components/Wrapper";
import events from "../models/project.json";
import renderHtml from "react-render-html";
function SingleProject({ match }) {
  const [project, setProject] = useState(null);
  useEffect(() => {
    const filterProject = events.filter((item) => {
      return item.slug == match.params.slug;
    });
    setProject(filterProject);
  }, [match.params.slug, events]);
  return (
    <Wrapper>
      <section className="about-banner">
        <div className="container">
          <div className="row">
            <div className="about-banner-text">
              <h2>{project && project[0].name}</h2>
              <p>
                The revolution is here, where are you? Poverty is removed not by
                sharing but by eradicating!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: "50px 0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {project && renderHtml(project[0].story)}
            </div>
            <div className="col-md-4">
              <img
                src={project && project[0].featured_image.url}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

export default SingleProject;
