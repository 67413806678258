import React from "react";
import { Link } from "react-router-dom";

function Event({ event }) {
  return (
    <div className="content">
      <Link to={`/event/${event.slug}`}>
        <div className="content-overlay" />
        <img className="content-image" src={event.gallery[0].url} />
      </Link>
      <div className="content-details fadeIn-bottom">
        <Link href={`/event/${event.slug}`}>
          <h3 className="content-title">{event.title}</h3>
        </Link>
        <div className="mt-4">
          <Link to={`/event/${event.slug}`} className="donate-bd">
            Details
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Event;
