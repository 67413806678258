import React from "react";
import Wrapper from "../components/Wrapper";

function ContactUs() {
  return (
    <Wrapper>
      <div>
        <section className="about-banner">
          <div className="container">
            <div className="row">
              <div className="about-banner-text">
                <h2>Contact Us</h2>
                <p>
                  The revolution is here, where are you? Poverty is removed not
                  by sharing but by eradicating!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section end */}
        {/* section about us */}
        <section className="about-us">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="about-us-text">
                  <h4>Contact Us </h4>
                  <p>National Child Relief Services</p>
                  <p>
                    1160, Ram Nagar colony, N.I.T Faridabad - 121006, Haryana,
                    India
                  </p>
                  <p>
                    {" "}
                    Phone: 0129-4300549 <br /> Mail: support@nationalcrs.org{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7020.595204410959!2d77.311427!3d28.380078000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdc4027fd1601%3A0x395db4d8ed7e029a!2sRam%20Nagar%2C%20New%20Industrial%20Town%2C%20Faridabad%2C%20Haryana%20121001!5e0!3m2!1sen!2sin!4v1605788699155!5m2!1sen!2sin"
                    width={"100%"}
                    height={350}
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default ContactUs;
