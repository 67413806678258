import React, { Component } from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Wrapper from "../components/Wrapper";
import Cause from "../components/common/Cause";
import Story from "../components/common/Story";
import Event from "../components/common/Event";
import Testimonial from "../components/common/Testimonial";
import { FacebookProvider, Page } from "react-facebook";
import causes from "../models/benificiary.json";
import stories from "../models/story.json";
import events from "../models/event.json";
import testimonials from "../models/testimonial.json";
import blogs from "../models/blog.json";
import Blog from "../components/common/Blog";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Wrapper>
      {/* banner */}
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="banner-text">
              <h4>HELP THE POOR</h4>
              <h2>For Their Better Future</h2>
              <p>
                Every day we bring hope to millions of children in the world's
                <br /> hardest places as a sign of God's unconditional love.
              </p>
              <div className="banner-button m-4">
                <Link to="/donate" className="donate-bd">
                  Donate Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Welcome screen */}
      <section className="Welcome">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="welcome-heading">
                <div className="welcome-heading-1">
                  <h2> It is the time to fight, are you contributing?</h2>
                </div>
                <div className="welcome-para">
                  <p>
                    National Child Relief Services (NCRS) is a registered NGO
                    functioning for the welfare of and underprivileged people of
                    the community. Our vision is to bring hope and change the
                    lives of underprivileged children and we function to ensure
                    the accessibility of school for all the children. This helps
                    them stay out of child labour and build a promising future
                    for themselves.
                  </p>
                  <p>
                    Apart from providing financial help to underprivileged
                    children suffering from chronic illness, we also organize
                    regular health camps & awareness camps for the people living
                    in nearby areas and sensitize them about various diseases
                    and other sensitive issues of society.
                  </p>
                </div>
                <div className="welcome-icon">
                  <div className="item-counter">
                    <i className="fa fa-users" />
                    <h4> 1250000+</h4>
                    <p>Poor People Fed</p>
                  </div>
                  <div className="item-counter">
                    <i className="fa fa-user-md" />
                    <h4> 250+</h4>
                    <p>Patients treated</p>
                  </div>
                  <div className="item-counter">
                    <i className="fa fa-shopping-bag" />
                    <h4> 3000+</h4>
                    <p>Ration Kits</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="welcome-img">
                <img src="images/welcome-1.jpg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* success story section  */}
      <section className="causes_area p_120">
        <div className="container">
          <div className="cause-text">
            <h2>Our Current Beneficiaries</h2>
            <p>
              There is no bigger thing than your concern. Great futures are
              built with a small charity, let’s go!
            </p>
          </div>
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              autoplay
              margin={10}
              responsiveClass={true}
              responsive={{
                0: {
                  items: 1,
                  nav: false,
                },
                600: {
                  items: 3,
                  nav: false,
                },
              }}
            >
              {causes &&
                causes.map((item, index) => {
                  if (!item.hide_on_menu) {
                    return <Cause key={index} cause={item}></Cause>;
                  }
                })}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* cause section  */}
      <section className="causes_area p_80" style={{ background: "#fff" }}>
        <div className="container">
          <div className="cause-text">
            <h2>Our Success Stories</h2>
            <p>
              Find out how much God has given you and from it take what you
              need; the remainder is needed by others.
            </p>
          </div>
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              autoplay
              margin={10}
              responsiveClass={true}
              responsive={{
                0: {
                  items: 1,
                  nav: false,
                },
                600: {
                  items: 3,
                  nav: false,
                },
              }}
            >
              {stories &&
                stories.map((story, index) => {
                  return <Story key={index} cause={story}></Story>;
                })}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* section out mission */}
      <section className="feature_area p_80">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset={0}
          data-background
          style={{ transform: "translateY(-7.75522px)" }}
        />
        <div className="container">
          <div className="white_title">
            <h2>Our Mission</h2>
            <p>
              NCRS mission is to be an equal opportunity provider for the
              upliftment of the underprivileged children & people by providing
              them timely education, shelter, food, medical support, care and
              confidence.
            </p>
          </div>
          <div className="row feature_inner">
            <div className="col-lg-4">
              <div className="feature_item">
                <i className="lnr lnr-diamond" />
                <h4>MEDICAL FACILITIES</h4>
                <p>
                  We provide financial help to poor people suffering from
                  chronic illness, and we also organize regular health camps &
                  awareness camps for the people living in nearby areas and
                  sensitize them about various diseases and other sensitive
                  issues of society.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature_item">
                <i className="lnr lnr-coffee-cup" />
                <h4>EDUCATION FOR ALL</h4>
                <p>
                  Children need to worry about their homework, not about putting
                  food on their own plate. In India around 7.1 million children
                  do not have access to school. We function to ensure the
                  accessibility of school for all the children.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature_item">
                <i className="lnr lnr-wheelchair" />
                <h4>NUTRITIOUS FOOD FOR THE POOR</h4>
                <p>
                  We provide robust health and nutrition benefits to the
                  children coming from the most marginalised communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="causes_area p_120" style={{ background: "#fff" }}>
        <div className="container">
          <div className="cause-text">
            <h2>Our Events</h2>
            <p>
              Help others and give something back. I guarantee you will discover
              that while public service improves the lives and the world around
              you, its greatest reward is the enrichment and new meaning it will
              bring your own life. ~Arnold Schwarzenegger
            </p>
          </div>
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay
              margin={10}
              responsiveClass={true}
              responsive={{
                0: {
                  items: 1,
                  nav: false,
                },
                600: {
                  items: 3,
                  nav: false,
                },
              }}
            >
              {events &&
                events.map((event, index) => {
                  return <Event key={index} event={event}></Event>;
                })}
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/* Testimonial section  */}
      <section className="causes_area p_120">
        <div className="container">
          <div className="cause-text">
            <h2>Our Testimonials</h2>
            <p>
              National Child Relief Services vision is to bring hope and change
              the lives of the India most poor and suffering children.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi_left_text">
                <h4>Testimonial from our Donors</h4>
                <p>Your kindness is someone’s hope!</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <OwlCarousel
                  className="owl-theme"
                  loop
                  autoplay
                  margin={10}
                  items={2}
                  responsiveClass={true}
                  responsive={{
                    0: {
                      items: 1,
                      nav: false,
                    },
                    600: {
                      items: 2,
                      nav: false,
                    },
                  }}
                >
                  {testimonials &&
                    testimonials.map((testimonial, index) => {
                      return (
                        <Testimonial
                          key={index}
                          testimonial={testimonial}
                        ></Testimonial>
                      );
                    })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* our social blocks */}
      <section className="social p_120" style={{ background: "#fff" }}>
        <div className="container">
          <div className="cause-text">
            <h2>Our Blogs</h2>
            <p>
              National Child Relief Services vision is to bring hope and change
              the lives of the India most poor and suffering children.
            </p>
          </div>
          <div className="row">
            <div className="col-md-8">
              {blogs &&
                blogs.map((blog, index) => {
                  return <Blog key={index} blog={blog}></Blog>;
                })}
            </div>
            <div className="col-md-4">
              <FacebookProvider appId="1264414050559265">
                <Page
                  href="https://www.facebook.com/nationalcrsorg/"
                  tabs="timeline"
                  style={{ width: "98%" }}
                />
              </FacebookProvider>
            </div>
          </div>
        </div>
      </section>
      {/*footer area  */}
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
