import React from "react";
import Wrapper from "../components/Wrapper";
import events from "../models/event.json";
import Event from "../components/common/Event";
function Events() {
  return (
    <Wrapper>
      <div>
        <section className="about-banner">
          <div className="container">
            <div className="row">
              <div className="about-banner-text">
                <h2>Events</h2>
                <p>
                  The revolution is here, where are you? Poverty is removed not
                  by sharing but by eradicating!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section end */}
        {/* section about us */}
        <section className="about-us gallery">
          <div className="container">
            <div className="row">
              {events &&
                events.map((event, index) => {
                  return (
                    <div className="col-md-4">
                      <Event key={index} event={event}></Event>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default Events;
