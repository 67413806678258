import React from "react";
import Wrapper from "../components/Wrapper";

function ThankYou() {
  return (
    <Wrapper>
      <div>
        <section className="about-banner">
          <div className="container">
            <div className="row">
              <div className="about-banner-text">
                <h2>THANK YOU</h2>
                <p>
                  The revolution is here, where are you? Poverty is removed not
                  by sharing but by eradicating!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="about-us">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="thank-you">
                  <h1>Thank You !</h1>
                  <p>Thank you for your great generosity!</p>
                  <p>
                    We, at National Child Relief Services, greatly appreciate
                    your donation. It is so heartening when we receive a gift
                    from a generous donor. Your support helps to further our
                    mission through different projects.
                  </p>
                  <p> Your support is invaluable to us, thank you again!</p>
                  <p>Follow Us on Social Media for latest updates:</p>
                  <div className="text-center" style={{ padding: "50px 0px" }}>
                    <a
                      href="https://www.linkedin.com/in/nationalcrs/"
                      target="_blank"
                      className="btn btn-danger mr-3"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://www.facebook.com/nationalcrsorg/"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      facebook
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default ThankYou;
