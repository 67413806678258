import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import renderHtml from "react-render-html";
import Wrapper from "../components/Wrapper";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
import docs from "../models/benificiary.json";

function Donation() {
  let query = useQuery();
  const [id, setid] = useState(query.get("cause"));
  const [amount, setAmount] = useState(query.get("amount"));
  const [submit, setsubmit] = useState(false);
  const [project, setproject] = useState(null);
  useEffect(() => {
    const project = docs.filter((item) => {
      return item.slug === id;
    });
    setproject(project[0]);
  }, []);
  return (
    <Wrapper>
      <section className="payment">
        <div className="container">
          <div className="row ">
            <div className="col-md-7">
              <div className="payment-t">
                <div className="payment-text">
                  <h3>Provide Details</h3>
                  <Formik
                    initialValues={{
                      currency: "Rs",

                      name: "",
                      email: "",
                      mobile: "",
                      notes: "",
                    }}
                    validationSchema={Yup.object({
                      currency: Yup.string().required("Required"),

                      name: Yup.string().required("Required"),
                      email: Yup.string().required("Required"),
                      mobile: Yup.string().required("Required"),
                    })}
                    onSubmit={(values) => {
                      setsubmit(true);
                      if (project.razorpay_url) {
                        const URI = encodeURI(
                          `${project.razorpay_url}?amount=${amount}&name=${values.name}&email=${values.email}&phone=${values.mobile}`
                        );
                        window.location.href = URI;
                      } else {
                        const URI = encodeURI(
                          `https://pages.razorpay.com/scanhome?amount=${amount}&name=${values.name}&email=${values.email}&phone=${values.mobile}`
                        );
                        window.location.href = URI;
                      }
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label htmlFor="inputState">Currency</label>
                              <select id="inputState" className="form-control">
                                <option selected>Rs.</option>
                              </select>
                            </div>
                            <div className="form-group col-md-8">
                              <label htmlFor="inputZip">Amount</label>
                              <input
                                type="number"
                                name="amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                className="form-control"
                                id="inputZip"
                                min={5}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label htmlFor="inputState">Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <p className="text-danger">
                                {" "}
                                {formik.errors.name &&
                                  formik.touched.name &&
                                  formik.errors.name}
                              </p>
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="inputZip">Email</label>
                              <input
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                id="inputZip"
                              />
                              <p className="text-danger">
                                {formik.errors.email &&
                                  formik.touched.email &&
                                  formik.errors.email}
                              </p>
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label htmlFor="inputState">Currency</label>
                              <select id="inputState" className="form-control">
                                <option selected>India (+91)</option>
                              </select>
                            </div>
                            <div className="form-group col-md-8">
                              <label htmlFor="inputZip">Phone</label>
                              <input
                                type="number"
                                className="form-control"
                                id="inputZip"
                                name="mobile"
                                value={formik.values.mobile}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <p className="text-danger">
                                {formik.errors.mobile &&
                                  formik.touched.mobile &&
                                  formik.errors.mobile}
                              </p>
                            </div>
                          </div>
                          <div
                            className="form-group row"
                            style={{ border: "1px solid #f1f1f1", padding: 20 }}
                          >
                            <div className="col-sm-8">
                              <p>Donate anonymously</p>
                              <p style={{ fontWeight: 700 }}>
                                Your contributions will be marked anonymous
                              </p>
                            </div>
                            <div className="col-sm-4">
                              <div
                                className="form-check"
                                style={{ paddingTop: 20 }}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="gridCheck1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="gridCheck1"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="form-group row"
                            style={{ border: "1px solid #f1f1f1", padding: 20 }}
                          >
                            <div className="col-sm-8">
                              <p>Donate on someone else's behalf</p>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="gridCheck1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="gridCheck1"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">
                              Drop a Note
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                          <div
                            className="text-center"
                            style={{ padding: "5px 0px" }}
                          >
                            <button
                              type="submit"
                              className="donate-bd-1"
                              style={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              Continue to pay ₹{amount}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            {project && (
              <div className="col-md-5">
                <div className="payment-opt text-center">
                  <p>Payment options: Online, cheque pickups</p>
                </div>
                <div
                  className
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <hr />
                  </div>
                  <div style={{ paddingTop: 5 }}>OR</div>
                  <div style={{ width: "40%" }}>
                    <hr />
                  </div>
                </div>
                <div className="pay-img text-center">
                  <img src="/images/scan-code.jpg" />
                </div>
                <div className="payment-opt text-center">
                  <p style={{ color: "#ea2c58" }}>
                    Scan &amp; donate with any app
                  </p>
                </div>
                <div className="payment-optio" style={{ padding: "0px 180px" }}>
                  <div>
                    <a href="#">
                      <img src="/images/bhim.png" />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <img src="/images/g-pay.png" />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <img src="/images/pay.png" />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <img src="/images/paytm.png" />
                    </a>
                  </div>
                </div>
                <div className="payment-opt text-center">
                  <a href style={{ color: "#ea2c58", paddingTop: 10 }}>
                    Download Payment Receipt
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

export default Donation;
