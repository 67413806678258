import React, { useEffect, useState } from "react";
import Wrapper from "../components/Wrapper";
import events from "../models/event.json";
function SingleEvent({ match }) {
  const [project, setProject] = useState(null);
  useEffect(() => {
    const filterProject = events.filter((item) => {
      return item.slug == match.params.slug;
    });
    setProject(filterProject);
  }, [match.params.slug, events]);
  return (
    <Wrapper>
      <div>
        <section className="about-banner">
          <div className="container">
            <div className="row">
              <div className="about-banner-text">
                <h2>{project && project[0].title}</h2>
                <p>
                  The revolution is here, where are you? Poverty is removed not
                  by sharing but by eradicating!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section end */}
        {/* section about us */}
        <section className="about-us gallery">
          <div className="container">
            <div className="row">
              {project &&
                project[0].gallery.map((item) => {
                  return (
                    <div className="col-md-4">
                      <img src={item.url} alt="National CRS" />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default SingleEvent;
