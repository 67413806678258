import React from 'react'
import Wrapper from '../components/Wrapper'

function TermAndCondition() {
    return (
        <div>
            <Wrapper>
                <div>
                    <section className="about-banner">
                        <div className="container">
                            <div className="row">
                                <div className="about-banner-text">
                                    <h2>Terms and Conditions</h2>

                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section end */}
                    {/* section about us */}
                    <section className="about-us ">
                        <div className="container">
                            <div className="row">

                                <p>Welcome to the National Child Relief Society (NCRS) website (hereinafter referred to as &quot;the Website&quot;). These Terms and Conditions govern your use of the Website and the services provided herein. By accessing or using the Website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you must not use the Website.</p>

                                <p>1. Acceptance of Terms</p>

                                <p>By accessing or using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. These Terms and Conditions constitute a legally binding agreement between you and the National Child Relief Society.</p>

                                <p>2. Use of the Website</p>

                                <p>The Website is intended for individuals who are at least 18 years old or the legal age of majority in their jurisdiction. By accessing or using the Website, you represent and warrant that you are at least 18 years old or the legal age of majority in your jurisdiction.</p>

                                <p>You agree to use the Website only for lawful purposes and in accordance with these Terms and Conditions. You further agree not to:</p>

                                <p>Use the Website in any way that violates any applicable laws or regulations. Use the Website to solicit donations or funds without prior authorization from the National Child Relief Society. Use the Website to distribute spam, viruses, or other harmful computer code. Use the Website to impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity. 3. Privacy Policy</p>

                                <p>Your privacy is important to us. Please review our Privacy Policy, which governs the collection, use, and disclosure of your personal information by the National Child Relief Society. By using the Website, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.</p>

                                <p>4. Intellectual Property</p>

                                <p>The content and materials available on the Website, including but not limited to text, graphics, logos, images, videos, and software, are owned by or licensed to the National Child Relief Society and are protected by copyright, trademark, and other intellectual property laws.</p>

                                <p>You may not reproduce, modify, distribute, or otherwise use any content or materials from the Website without the prior written consent of the National Child Relief Society.</p>

                                <p>5. Disclaimer of Warranties</p>

                                <p>The Website is provided on an &quot;as is&quot; and &quot;as available&quot; basis, without any warranties of any kind, express or implied. The National Child Relief Society does not warrant that the Website will be uninterrupted or error-free, that defects will be corrected, or that the Website or the servers that make it available are free of viruses or other harmful components.</p>

                                <p>6. Limitation of Liability</p>

                                <p>In no event shall the National Child Relief Society be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, arising out of or in connection with your use of the Website or these Terms and Conditions.</p>

                                <p>7. Indemnification</p>

                                <p>You agree to indemnify and hold harmless the National Child Relief Society, its officers, directors, employees, agents, and affiliates, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising from:</p>

                                <p>Your use of and access to the Website. Your violation of any term of these Terms and Conditions. Your violation of any third-party right, including without limitation any copyright, property, or privacy right. 8. Changes to Terms and Conditions</p>

                                <p>The National Child Relief Society reserves the right to modify or revise these Terms and Conditions at any time, without prior notice. Your continued use of the Website following the posting of any changes to these Terms and Conditions will constitute your acceptance of such changes.</p>

                                <p>9. Governing Law</p>

                                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.</p>

                                <p>10. Contact Us</p>

                                <p>If you have any questions about these Terms and Conditions, please contact us at contact@nationalcrs.org.in.</p>

                                <p>Thank you for visiting the National Child Relief Society website. We appreciate your support in helping children receive medical treatment and support.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </Wrapper>
        </div>
    )
}

export default TermAndCondition