import React from 'react'
import Wrapper from '../components/Wrapper'

function PrivacyPolicy() {
    return (
        <div>
            <Wrapper>
                <div>
                    <section className="about-banner">
                        <div className="container">
                            <div className="row">
                                <div className="about-banner-text">
                                    <h2>Privacy Policy</h2>

                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section end */}
                    {/* section about us */}
                    <section className="about-us ">
                        <div className="container">
                            <div className="row">

                                <p>At National Child Relief Society (NCRS), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you visit our website https://nationalcrs.org.in/ (hereinafter referred to as &quot;the Website&quot;) and the choices you have associated with that information. By accessing or using the Website, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.</p>

                                <p>1. Information We Collect</p>

                                <p>When you visit the Website, we may collect certain information about you, including:</p>

                                <p>Personal Information: When you make a donation, sign up for our newsletter, or contact us through the Website, we may collect personal information such as your name, email address, phone number, and mailing address. Non-Personal Information: We may also collect non-personal information such as your IP address, browser type, operating system, and other technical information about your use of the Website. 2. Use of Information</p>

                                <p>We may use the information we collect for various purposes, including:</p>

                                <p>Providing and improving the Website: We use the information to operate, maintain, and improve the Website and our services. Communicating with you: We may use your contact information to send you newsletters, updates, and other communications related to our activities and programs. Processing donations: If you make a donation through the Website, we may use your personal information to process your donation and send you a receipt. 3. Disclosure of Information</p>

                                <p>We may disclose your personal information to third parties in the following circumstances:</p>

                                <p>Service Providers: We may share your information with third-party service providers who assist us in operating the Website and providing our services. Legal Requirements: We may disclose your information if required to do so by law or in response to a subpoena or court order. Consent: We may disclose your information to third parties with your consent. 4. Data Security</p>

                                <p>We take reasonable measures to protect the security of your personal information and to ensure that it is treated securely and in accordance with this Privacy Policy. However, no method of transmission over the Internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>

                                <p>5. Third-Party Links</p>

                                <p>The Website may contain links to third-party websites that are not operated by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.</p>

                                <p>6. Children's Privacy</p>

                                <p>The Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.</p>

                                <p>7. Changes to Privacy Policy</p>

                                <p>We reserve the right to modify or revise this Privacy Policy at any time, without prior notice. Your continued use of the Website following the posting of any changes to this Privacy Policy will constitute your acceptance of such changes.</p>

                                <p>8. Contact Us</p>

                                <p>If you have any questions about this Privacy Policy, please contact us at contact@nationalcrs.org.in.</p>

                                <p>Thank you for visiting the National Child Relief Society website. We appreciate your support in helping children receive medical treatment and support.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </Wrapper>
        </div>
    )
}

export default PrivacyPolicy