import React from "react";
import Wrapper from "../components/Wrapper";
import gallery from "../models/gallery.json";
function Gallery() {
  return (
    <Wrapper>
      <div>
        <section className="about-banner">
          <div className="container">
            <div className="row">
              <div className="about-banner-text">
                <h2>Gallery</h2>
                <p>
                  The revolution is here, where are you? Poverty is removed not
                  by sharing but by eradicating!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section end */}
        {/* section about us */}
        <section className="about-us gallery">
          <div className="container">
            <div className="row">
              {gallery &&
                gallery.map((item) => {
                  return (
                    <div className="col-md-4">
                      <img src={item.url} alt="National CRS" />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default Gallery;
