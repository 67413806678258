import React from 'react'
import Wrapper from '../components/Wrapper'

function RefundPolicy() {
    return (
        <div>
            <Wrapper>
                <div>
                    <section className="about-banner">
                        <div className="container">
                            <div className="row">
                                <div className="about-banner-text">
                                    <h2>Refund Policy</h2>

                                </div>
                            </div>
                        </div>
                    </section>
                    {/* section end */}
                    {/* section about us */}
                    <section className="about-us ">
                        <div className="container">
                            <div className="row">

                                <p>Thank you for your support of the National Child Relief Society (NCRS) and our mission to help children receive medical treatment and support. Your donation is vital in enabling us to provide assistance to those in need. This Refund Policy explains our policy regarding donations made through our website https://nationalcrs.org.in/ (hereinafter referred to as &quot;the Website&quot;).</p>

                                <p>1. Donation Policy</p>

                                <p>All donations made to the National Child Relief Society are considered final and non-refundable. Once a donation has been processed, it cannot be refunded, unless there has been an error in processing or if the donation was made fraudulently.</p>

                                <p>2. Error in Processing</p>

                                <p>If you believe that an error has occurred in the processing of your donation, please contact us immediately at contact@nationalcrs.org.in. We will investigate the matter and, if an error is found, we will refund the donation amount to the original payment method.</p>

                                <p>3. Fraudulent Donations</p>

                                <p>If you suspect that a donation has been made fraudulently using your payment information, please contact us immediately at contact@nationalcrs.org.in. We take fraudulent activity seriously and will work with you to resolve the issue as quickly as possible.</p>

                                <p>4. Changes to Donation</p>

                                <p>Once a donation has been processed, it cannot be changed or modified. If you wish to make changes to your donation amount or designation, please contact us before completing the donation transaction.</p>

                                <p>5. Contact Us</p>

                                <p>If you have any questions or concerns about our Refund Policy, please contact us at contact@nationalcrs.org.in. We are here to assist you and appreciate your support of our organization's mission.</p>

                                <p>Thank you for your generosity and for making a difference in the lives of children in need.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </Wrapper>
        </div>
    )
}

export default RefundPolicy