import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import projects from "../models/project.json";

function Wrapper({ children }) {
  const [menu, setmenu] = useState({
    projects: false,
    contact: false,
    getHelp: false,
    volunteer: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <section className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Link to="/">
                <div className="logo">
                  <img src="/images/logo.png" className="logo-img" />
                </div>
              </Link>
            </div>
            <div className="col-md-9">
              <div className="mobile-bu">
                <a
                  className="donate-bd"
                  href="#"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  {menuOpen ? (
                    <i className="fa fa-close" />
                  ) : (
                    <i className="fa fa-bars" />
                  )}
                </a>
              </div>
              <div
                className="top-menu"
                style={{
                  display:
                    window.innerWidth < 600
                      ? menuOpen
                        ? "block"
                        : "none"
                      : "block",
                }}
              >
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li
                    onMouseEnter={() => setmenu({ projects: true })}
                    onMouseLeave={() => setmenu({ projects: false })}
                  >
                    <Link to="#">Projects</Link>

                    <ul
                      style={{ display: menu.projects ? "block" : "none" }}
                      className="dropdown-nav"
                    >
                      {projects &&
                        projects.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link to={`/project/${item.slug}`}>
                                {item.name}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>

                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link className="donate-bd" to="/donate">
                      Donate Now
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {children}
      <footer className="footer-area section_gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="single-footer-widget">
                <h6 className="footer_title">About National CRS</h6>
                <p style={{ color: "#777" }}>
                  The world has become so fast paced that people don’t want to
                  stand by reading a page of information, they would much rather
                  look at a presentation and understand the message. It has come
                  to a point{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Quick Links</h6>
                <ul className="list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/project/healing-india">Healing India</Link>
                  </li>
                  <li>
                    <Link to="/project/liberate-india">Literate India</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Important Links</h6>
                <ul className="list">
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <h6 className="footer_title">Terms &amp; Policies</h6>
                <ul className="list">
                  <li>
                    <Link to="/donate">Donate </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Condition</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-center">
            <p style={{ color: "#fff", paddingTop: 50 }}>
              Copyright ©2020 Nationalcrs.com
            </p>
          </div>
          <div className="social-links ">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/nationalcrsorg/"
                  target="_blank"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/nationalcrs/"
                  target="_blank"
                >
                  <i className="fa fa-linkedin" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/in/nationalcrs/"
                  target="_blank"
                >
                  <i className="fa fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://plus.google.com/110250031311604128723/posts/JboWDRQrnRJ"
                  target="_blank"
                >
                  <i className="fa fa-google-plus" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Wrapper;
