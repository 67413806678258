import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import cause from "./cause";

export default combineReducers({
  auth,
  alert,
  cause,
});
