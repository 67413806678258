import React from "react";
import { Route, Switch } from "react-router-dom";
import Donation from "./Pages/Donation";
import Donate from "./Pages/Donate";
import Home from "./Pages/Home";
import SingleCause from "./Pages/SingleCause";
import SingleStory from "./Pages/SingleStory";
import BeforeLoginRoutes from "./Routes/BeforeLoginRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";
import AboutUs from "./Pages/AboutUs";
import Gallery from "./Pages/Gallery";
import Events from "./Pages/Events";
import SingleEvent from "./Pages/SingleEvent";
import News from "./Pages/News";
import SingleProject from "./Pages/SingleProject";
import ContactUs from "./Pages/ContactUs";
import ThankYou from "./Pages/ThankYou";
import TermAndCondition from "./Pages/TermAndCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/current-beneficiaries/:slug"
        component={SingleCause}
      />
      <Route exact path="/story/:slug" component={SingleStory} />

      <Route exact path="/donation" component={Donation} />
      <Route exact path="/donate" component={Donate} />
      <Route exact path="/about-us" component={AboutUs} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/event/:slug" component={SingleEvent} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/news" component={News} />
      <Route exact path="/terms-and-conditions" component={TermAndCondition} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/refund-policy" component={RefundPolicy} />
      <Route exact path="/thank-you" component={ThankYou} />

      <Route exact path="/project/:slug" component={SingleProject} />
    </Switch>
  );
}

export default Routes;
