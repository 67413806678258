import React from "react";
import Wrapper from "../components/Wrapper";

function AboutUs() {
  return (
    <Wrapper>
      <div>
        <section className="about-banner">
          <div className="container">
            <div className="row">
              <div className="about-banner-text">
                <h2>ABOUT US</h2>
                <p>
                  The revolution is here, where are you? Poverty is removed not
                  by sharing but by eradicating!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section end */}
        {/* section about us */}
        <section className="about-us">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="about-us-text">
                  <p>
                    National Child Relief Services is involved in a number of
                    relief programs for the betterment of the lives of poor
                    Indian children. The Trust takes up appropriate measures and
                    adopts suitable programs for rehabilitating people in
                    different circumstances.
                  </p>
                  <p>
                    National Child Relief Services plays an important part in
                    protecting the global environment as well. A lot of
                    awareness is created amongst the Indian people on deadly
                    diseases like Heart Disease, Cancer, etc. for which camps
                    are set up and run all over the India from time to
                    time.Children living in the slums are also provided
                    nutritious food, medicines and clothing free of cost.The
                    other objective of the Trust is the instituting of
                    Secondary, Primary and Middle level schools for offering
                    free education to the poor and street children.All the
                    support and indoctrination required are offered by National
                    Child Relief Services while addressing epidemics related to
                    AIDs and HIV. To agencies in the private sector, governments
                    of developing nations and non-government organizations, the
                    Trust provides white-collar and technical assistance as well
                    as all-embracing training. The Trust also designs, manages
                    and evaluates all-inclusive prevention of HIV and AIDS,
                    offers support and care sequencers and also provides
                    answerability in management of finances and the results of
                    the programs, both.Some of the relief measures taken by
                    National Child Relief Services. Distribution of Clothes
                  </p>
                  <p>
                    Where programs for food relief are carried out, blankets and
                    clothes are also distributed by this NGO, free of cost. You
                    can check out the photos on the website to get an idea. Many
                    of the poor children come in old shorts and some have hardly
                    any clothing to wear. They are provided clothes by National
                    Child Relief Services Distribution of Free Medicines The
                    poor and needy children are distributed free medication in
                    the relief programs offering medicine. It has been decided
                    that in future too, people will be provided with free
                    services. The needy and the poor in rural India are also
                    provided with medical and surgical treatment. This free of
                    cost treatment is provided by a small team of medical
                    volunteers at National Child Relief Services. .
                  </p>



                </div>
                {/* <div className="welcome-icon">
                  <div className="item-counter">
                    <i className="fa fa-user" />
                    <h4> 5000</h4>
                    <p>dry snacks packets provided to migrant labourers</p>
                  </div>
                  <div className="item-counter">
                    <i className="fa fa-user" />
                    <h4> 875</h4>
                    <p>
                      Families provided with Ration Packages (during Covid19)
                    </p>
                  </div>
                  <div className="item-counter">
                    <i className="fa fa-user" />
                    <h4> 1248</h4>
                    <p>Cancer patients have been treated</p>
                  </div>
                  <div className="item-counter">
                    <i className="fa fa-user" />
                    <h4> 1248</h4>
                    <p>Poor People Fed</p>
                  </div>
                </div> */}
                <div className="who-we">
                  <h3>Legal</h3>
                  <p></p>
                  <p>
                    Founded in 2015 by Kanhayia Choudhary and Sachin Kumar,
                    National Child Relief Services is registered under the
                    Indian Trust Act of 1882, Section 60 (Registration no. 223).
                    At National CRS, we believe in complete transparency with
                    our shareholders and thus all our important legal documents
                    are available for public scrutiny as a proof of our
                    legitimacy. Legal Documents
                  </p>
                  <p>
                    <div>
                      <a
                        target="_blank"
                        href="/images/legal/Registration-copy.pdf"
                        style={{ color: "#00F" }}
                      >
                        {" "}
                        REGISTRATION DOCUMENT
                      </a>
                      <br />
                      <a
                        target="_blank"
                        href="/images/legal/national-child-relief-services-pencard.pdf"
                        style={{ color: "#00F" }}
                      >
                        PAN CARD
                      </a>
                      <br />
                      <a
                        target="_blank"
                        href="/images/legal/12A-Certificate-W-copy.pdf"
                        style={{ color: "#00F" }}
                      >
                        12A REGISTRATION COPY
                      </a>
                      <br />
                      <a
                        target="_blank"
                        href="/images/legal/80G-Certificate-copy.pdf"
                        style={{ color: "#00F" }}
                      >
                        80G CERTIFICATE COPY
                      </a>
                      <br />
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

export default AboutUs;
