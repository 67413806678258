import React from "react";

function Testimonial({ testimonial }) {
  return (
    <div className="testi_item">
      <p>{testimonial.testimonial}</p>
      <h4>{testimonial.name}</h4>
    </div>
  );
}

export default Testimonial;
