import React from "react";
import { Link } from "react-router-dom";

function Blog({ blog }) {
  return (
    <div className="event_item" style={{ marginBottom: 30 }}>
      <div className="media">
        <div className="d-flex">
          <img src={blog.blog_image.url} alt="National CRS" />
        </div>
        <div className="media-body">
          <a href="#"> {blog.date} </a>
          <a href="#">
            <h4>{blog.title}</h4>
          </a>
          <p>{blog.description}</p>
          <div>
            <Link> Read More </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
