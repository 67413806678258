import React, { useEffect, useState } from "react";
import renderHtml from "react-render-html";
import Wrapper from "../components/Wrapper";
import projects from "../models/benificiary.json";

function SingleCause({ match }) {
  const [project, setProject] = useState(null);
  useEffect(() => {
    const filterProject = projects.filter((item) => {
      return item.slug == match.params.slug;
    });
    setProject(filterProject);
  }, [match.params.slug, projects]);
  const [activeTab, setActiveTab] = useState("story");
  const [donation, setDonation] = useState(500);
  const donateAmount = () => {
    console.log(project);
    window.location = `/donation?amount=${donation}&cause=${project[0].slug}`;
  };
  const openFacebookURL = () => {
    console.log(window.location.pathname);
    const url = `http://ncrsngo.org${window.location.pathname}`;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "facebook-share-dialog",
      "width=626,height=436"
    );
  };
  return (
    <Wrapper>
      <section className="donate">
        <div className="container">
          <div className="row ">
            {project ? (
              project.map((item) => {
                return (
                  <div className="col-md-8 ">
                    <div className="donate-image">
                      <img src={item.profile && item.profile.url} />
                    </div>
                    <div className="title-name">
                      <h3>{item.title}</h3>
                    </div>
                    <div className="share-button">
                      <a
                        href="#"
                        onClick={() => openFacebookURL()}
                        className="share-fb"
                      >
                        <i className="fa fa-facebook" />
                        FACEBOOK{" "}
                      </a>
                      <a
                        className="share-wt"
                        href={`https://api.whatsapp.com/send?text=http://ncrsngo.org${window.location.pathname}`}
                        data-action="share/whatsapp/share"
                        target="_blank"
                      >
                        <i className="fa fa-whatsapp" />
                        WHATSAPP{" "}
                      </a>
                    </div>
                    <div className="dis-t">
                      <div className="">
                        <div className="tabs">
                          <button
                            onClick={() => setActiveTab("story")}
                            className={
                              activeTab === "story" ? "donate-bd btn" : "btn"
                            }
                          >
                            STORY
                          </button>
                          <button
                            onClick={() => setActiveTab("documents")}
                            className={
                              activeTab === "documents"
                                ? "donate-bd btn"
                                : "btn"
                            }
                          >
                            DOCUMENTS
                          </button>
                          <button
                            onClick={() => setActiveTab("updates")}
                            className={
                              activeTab === "updates" ? "donate-bd btn" : "btn"
                            }
                          >
                            UPDATES
                          </button>
                        </div>
                        <div className="">
                          {activeTab === "story" && (
                            <div className="story pt-3">
                              {renderHtml(item.story)}
                            </div>
                          )}
                          {activeTab === "documents" && (
                            <div className="story pt-3">
                              <iframe
                                src={item.document && item.document.url}
                                width="100%"
                                height="550px"
                              ></iframe>
                            </div>
                          )}
                          {activeTab === "updates" && (
                            <div className="story pt-3">
                              {item.updates &&
                                item.updates.map((update, index) => {
                                  return (
                                    <div
                                      style={{
                                        border: "1px solid #f1f1f1",
                                        padding: "10px",
                                        marginBottom: "10px",
                                      }}
                                      key={index}
                                    >
                                      {update.update}
                                    </div>
                                  );
                                })}
                            </div>
                          )}

                          <div className="panel">
                            <div className="donate-section">
                              <div
                                className="donate-amount"
                                style={{ paddingTop: 10 }}
                              >
                                <h3>Donate</h3>
                                <div className="form-row">
                                  <div className="form-group col-md-4">
                                    <label htmlFor="inputState">Rs</label>
                                    <select
                                      id="inputState"
                                      className="form-control"
                                    >
                                      <option selected>Rs.</option>
                                    </select>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label htmlFor="inputZip">Amount</label>
                                    <input
                                      type="numbers"
                                      value={donation}
                                      onChange={(e) =>
                                        setDonation(e.target.value)
                                      }
                                      className="form-control"
                                      id="inputZip"
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <div
                                      className
                                      style={{ padding: "38px 0px" }}
                                    >
                                      <button
                                        onClick={() => donateAmount()}
                                        className="donate-bd-2"
                                      >
                                        Donate (₹{donation})
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="payment-opt text-center">
                                  <p>Payment options: Online, cheque pickups</p>
                                </div>
                                <div
                                  className
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ width: "40%" }}>
                                    <hr />
                                  </div>
                                  <div style={{ paddingTop: 5 }}>OR</div>
                                  <div style={{ width: "40%" }}>
                                    <hr />
                                  </div>
                                </div>
                                <div className="pay-img text-center">
                                  <img src="/images/scan-code.jpg" />
                                </div>
                                <div className="payment-opt text-center">
                                  <p style={{ color: "#ea2c58" }}>
                                    Scan &amp; donate with any app
                                  </p>
                                </div>
                                {/*payment option  */}
                                <div
                                  className="payment-optio"
                                  style={{ padding: "0px 180px" }}
                                >
                                  <div>
                                    <a href="#">
                                      <img src="/images/bhim.png" />
                                    </a>
                                  </div>
                                  <div>
                                    <a href="#">
                                      <img src="/images/g-pay.png" />
                                    </a>
                                  </div>
                                  <div>
                                    <a href="#">
                                      <img src="/images/pay.png" />
                                    </a>
                                  </div>
                                  <div>
                                    <a href="#">
                                      <img src="/images/paytm.png" />
                                    </a>
                                  </div>
                                </div>
                                <div className="payment-opt text-center">
                                  <a
                                    href
                                    style={{ color: "#ea2c58", paddingTop: 10 }}
                                  >
                                    Download Payment Receipt
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div> Loading... </div>
            )}
            {project &&
              project.map((item) => {
                const goal = item.goal;
                const totalDonation = item.achieved;
                const angleOfRotation = (totalDonation / goal) * 360;
                const percentageDonation = parseInt(
                  (totalDonation / goal) * 100
                );
                return (
                  <div className="col-md-4">
                    <div className="donate-section">
                      <div className="donate-sec-m">
                        <div className="progresss">
                          <div
                            className="radial-progress"
                            style={{
                              backgroundColor:
                                angleOfRotation > 180 ? "#fed857" : "#333",
                            }}
                          >
                            <div>
                              <div className="circle">
                                <div
                                  className="mask full"
                                  style={{
                                    transform: `rotate(${angleOfRotation}deg)`,
                                  }}
                                >
                                  <div
                                    className="fill"
                                    style={{
                                      transform: `rotate(${angleOfRotation}deg)`,
                                      backgroundColor:
                                        angleOfRotation > 180
                                          ? "#333"
                                          : "#fed857",
                                    }}
                                  />
                                </div>
                                <div className="mask half">
                                  <div className="fill" />
                                  <div className="fill fix" />
                                </div>
                                <div className="shadow" />
                              </div>
                              <div className="inset">
                                <div className="percentage">
                                  <div className="numbers">
                                    <span>
                                      {parseInt(
                                        (item.achieved / item.goal) * 100
                                      )}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="progress-description">
                            <div className="flex-row">
                              <div>
                                <p>
                                  Goal <br></br> <span> {item.goal} </span>
                                </p>
                              </div>
                            </div>
                            <div className="flex-row">
                              <div className="">
                                <p>
                                  Raised <br></br>
                                  <span> {item.achieved} </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* end  */}
                      <div className="donate-amount">
                        <h3>Donate</h3>
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label htmlFor="inputState">Rs</label>
                            <select id="inputState" className="form-control">
                              <option selected>Rs.</option>
                            </select>
                          </div>
                          <div className="form-group col-md-8">
                            <label htmlFor="inputZip">Amount</label>
                            <input
                              type="number"
                              value={donation}
                              min={5}
                              onChange={(e) => setDonation(e.target.value)}
                              className="form-control"
                              id="inputZip"
                            />
                          </div>
                        </div>
                        <div className style={{ padding: "5px 0px" }}>
                          <button
                            onClick={() => donateAmount()}
                            className="donate-bd-1"
                          >
                            Donate (₹{donation})
                          </button>
                        </div>
                        <div className="payment-opt">
                          <p>Payment options: Online, cheque pickups</p>
                        </div>
                        <div
                          className
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "40%" }}>
                            <hr />
                          </div>
                          <div style={{ paddingTop: 5 }}>OR</div>
                          <div style={{ width: "40%" }}>
                            <hr />
                          </div>
                        </div>
                        <div className="pay-img text-center">
                          <img src="/images/scan-code.jpg" />
                        </div>
                        <div className="payment-opt text-center">
                          <p style={{ color: "#ea2c58" }}>
                            Scan &amp; donate with any app
                          </p>
                        </div>
                        {/*payment option  */}
                        <div className="payment-optio">
                          <div>
                            <a href="#">
                              <img src="/images/bhim.png" />
                            </a>
                          </div>
                          <div>
                            <a href="#">
                              <img src="/images/g-pay.png" />
                            </a>
                          </div>
                          <div>
                            <a href="#">
                              <img src="/images/pay.png" />
                            </a>
                          </div>
                          <div>
                            <a href="#">
                              <img src="/images/paytm.png" />
                            </a>
                          </div>
                        </div>
                        <div className="payment-opt text-center">
                          <a href style={{ color: "#ea2c58", paddingTop: 10 }}>
                            Download Payment Receipt
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

export default SingleCause;
