import React, { useState } from "react";
import renderHtml from "react-render-html";
import { Link } from "react-router-dom";

function Cause({ cause }) {
  const [donation, setDonation] = useState(500);
  return (
    <div className="causes_item">
      <Link to={`/current-beneficiaries/${cause.slug}`}>
        <div className="causes_img">
          <img
            className="img-fluid"
            src={cause.profile && cause.profile.url}
            alt
          />
        </div>
        <div className="causes_text">
          <h4>
            {cause.name} ({cause.age} )
          </h4>
          <p className="diases">{cause.disease}</p>
          <div className="cause-description">{renderHtml(cause.story)}</div>
        </div>
        <div className="goal-container">
          <div className="achieved">
            {parseInt((cause.achieved / cause.goal) * 100)}%
          </div>
        </div>
        <div className="prgoress-bar">
          <div className="bar">
            <div
              className="bar-inner"
              style={{ width: `${(cause.achieved / cause.goal) * 100}%` }}
            />
          </div>
        </div>
        <div className="causes_bottom">
          <a href="#">Raised: ₹{cause.achieved}</a>
          <a href="#">Total Need: ₹{cause.goal}</a>
        </div>
      </Link>
    </div>
  );
}

export default Cause;
